import React, { useEffect } from "react"
import "../../components/CertificadosComponents/certificados.css"
import CertificadosIndex from "../../components/CertificadosComponents/"
import LayoutProvider from "../../../src/components/Layout"
import { navigate } from 'gatsby';

const CertificadosPage = ({ location }) => {
  useEffect(() => {
    const logueado = localStorage.getItem("logueado")
    const requiere_actualizar = localStorage.getItem("requiere_actualizar")

    if (logueado === false || logueado === null || logueado === undefined) {
        navigate("/")
    } else if (requiere_actualizar === true || requiere_actualizar === "true") {
        navigate("/inicio/editar-perfil/")
    }
  }, [])

  return (
    <LayoutProvider title="Certificados" location={location}>
          <CertificadosIndex />
    </LayoutProvider>
  )
}

export default CertificadosPage
