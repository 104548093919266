import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { ConsultaAfiliacionPDFF } from "../../state/actions/Certificados/AfiliacionActions"
import { ConsultaAfiliacionF } from "../../state/actions/Dashboard/DatosActions"
import {ToastContainer, toast} from 'react-toastify';
import {FaFileAlt} from "react-icons/fa"
import {
  ConsultaRnpiF,
} from "../../state/actions/Certificados/RnpiActions"

const CertificadosIndex = () => {
    const dispatch = useDispatch()
    const Afiliacion = useSelector(state => state.Datosmisuper.Afiliacion)
    const Rnpi = useSelector(state => state.Rnpi.Rnpi)
    const [Descargando, setDescargando] = useState(false)

    const PDFAfiliacion = useSelector(state => state.Afiliacion.PDFAfiliacion)
    const DescargaCertificado = () => {
      
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-3535013-1');
    gtag("event", "Inicio de la solicitud Certificado Afiliación ", {
                  event_category: "Certificado de Afiliación ",
                  event_label: "Trámite - ID 0300520002 "
    });

    gtag("event", "Fin de la solicitud Certificado Afiliación ", {
      event_category: "Certificado de Afiliación ",
      event_label: "Trámite - ID 0300520002 "
});
        if( PDFAfiliacion.length !== 0){
        const link = document.createElement("a")
        link.setAttribute(
          "href",
          "data:application/octet-stream;base64," +
            encodeURIComponent(PDFAfiliacion[0].pdf_text)
        )
        link.setAttribute("download", "Afiliacion.pdf")
        link.style.display = "none"
        document.body.appendChild(link)
        link.click()
        
        toast.success("Descarga exitosa.")
        
      }else{
  
        const ConsultaDescargaCertificado = () =>
        dispatch(ConsultaAfiliacionPDFF( setDescargando, PDFAfiliacion))
        ConsultaDescargaCertificado()
      }
    }
  
    useEffect(() => {
      if (Afiliacion.length === 0) {
        const ConsultaAfiliacion = () => dispatch(ConsultaAfiliacionF())
        ConsultaAfiliacion()
      }
      if (Rnpi.length === 0) {
        const ConsultaRnpi = () => dispatch(ConsultaRnpiF())
        ConsultaRnpi()
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


  return (
    <>
    
    <ToastContainer closeOnClick={true} pauseOnHover={true} />
      <div className="contenedor ">
        <div className="dashboard-option-ss">
          <div
            className="option-principal-ss option-certificados"
           
          >
            <div className="d-flex" onClick={() => navigate("/inicio/certificados/certificados-de-reclamos/")}>
              <div>
                <FaFileAlt
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#0073ba",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                 Certificado de Reclamo en Trámite
                </p>
                <small style={{ display: "block" }}>
                Documento que acredita que una persona tiene un reclamo en tramitación ante la Superintendencia de Salud. Permite a los establecimientos de salud (Clínicas, - Hospitales) evaluar la autorización de eventuales prórrogas en el cobro de los documentos entregados en garantía (cheques, pagarés, etc.).

                </small>
              </div>
            </div>
          </div>
        </div>

                    
        {Rnpi !== null && Rnpi.length !== 0 &&
        <div className="dashboard-option-ss">
          <div className="option-principal-ss option-reclamos">
            <div className="d-flex"  onClick={() => navigate("/inicio/certificados/certificados-rnpi/")}>
              <div>
                <FaFileAlt
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#00BB3A",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Certificado de Inscripción en el Registro Nacional de Prestadores Individuales de Salud
                </p>
                <small className="d-block">
                Documento que acredita si un prestador individual se encuentra inscrito en el "Registro Nacional de Prestadores Individuales" de la Superintendencia de Salud.
                </small>
                
                <small className="d-block mt-4">
                Las/os beneficiarias/os de este certificado son: médicos cirujanos, cirujanos dentistas, enfermeras/os, matronas/es, tecnólogos médicos, psicólogas/os, kinesiólogas/os, farmacéuticas/os, químicos farmacéuticos, bioquímicas/os, nutricionistas, fonoaudiólogas/os, terapeutas ocupacionales y profesionales auxiliares que estén inscritos en la Superintendencia de Salud.
                </small>

              </div>
            </div>
          </div>
        </div>
        }
        
        <div className="dashboard-option-ss">
          <div className="option-principal-ss option-excedentes">
            <div className="d-flex"  onClick={() => DescargaCertificado()}>
              <div>
                <FaFileAlt
                  style={{
                    fontSize: 24,
                    fontWeight: "normal",
                    color: "#DC3A35",
                    marginTop: 15,
                  }}
                />
              </div>

              <div className="ml-3">
                <p
                  className="py-0"
                  style={{
                    color: "#444444",
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  Certificado de afiliación al sistema de isapres

                </p>
                <small className="d-block">
                Documento que acredita la afiliación de una persona a una Isapre (Institución de Salud Previsional) y la calidad de beneficiario/a (cotizante o carga) que posee en un determinado contrato de salud. 

                </small>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

const mapStateToProps = state => {
  return state
}

export default connect(mapStateToProps)(CertificadosIndex)
