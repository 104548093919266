import { GET_RNPI, GET_PDF_RNPI } from "../../types"
import clienteAxios from "../../../Utils/axios"
import { toast } from "react-toastify"

const Get_PDF_Rnpi_F = datos => ({
  type: GET_PDF_RNPI,
  payload: datos,
})

export function ConsultaRnpiF() {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)

      const respuesta = await clienteAxios.post("Rnpi/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })
      if (respuesta.data !== "") {
        dispatch(Get_Rnpi_F(respuesta.data.datos_rnpi))
      } else {
        dispatch(Get_Rnpi_F([]))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const Get_Rnpi_F = datos => ({
  type: GET_RNPI,
  payload: datos,
})

export function ConsultaRnpiPDFF(setDescargando, PDFRnpi) {
  return async (dispatch) => {
    try {

      setDescargando(true)
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)

      const respuesta = await clienteAxios.post(
        "PrestadorIndividualPDF/",
        dataForm,
        {
          headers: {
            Authorization: "Bearer " + tokenaccess,
            accept: "application/json",
          },
        }
      )

      const link = document.createElement("a")
      link.setAttribute(
        "href",
        "data:application/octet-stream;base64," +
          encodeURIComponent(respuesta.data.pdf_text)
      )
      link.setAttribute("download", "Prestador.pdf")
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()
      
            
      if(PDFRnpi.length === 0 ){
        dispatch(Get_PDF_Rnpi_F([{
          pdf_text: respuesta.data.pdf_text
        }]))
      }else{
  
        PDFRnpi.push({
          pdf_text: respuesta.data.pdf_text
        })
        dispatch(Get_PDF_Rnpi_F(PDFRnpi))
  
      }

      setDescargando(false)
      // gtag('config', 'UA-3535013-1');
      // gtag("event", "Fin de la solicitud de Certificado  ", {
      //               event_category: "Certificado Registro RNPI",
      //               event_label: "Trámite ID - 0300520008 "
      // });
  
      toast.success("Descarga exitosa.")
    } catch (error) {
      console.log(error)
      setDescargando(false)
      toast.success("Ocurrio un error con el servidor, intente mas tarde.")
    }
  }
}
