import clienteAxios from "../../../Utils/axios"
import { toast } from "react-toastify"
import { GET_PDF_AFILIACION } from "../../types"

const Get_PDF_Afiliacion = datos => ({
  type: GET_PDF_AFILIACION,
  payload: datos,
})

export function ConsultaAfiliacionPDFF(setDescargando, PDFAfiliacion) {
  return async (dispatch) => {
    try {
      setDescargando(true)
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)

      const respuesta = await clienteAxios.post("AfiliacionPDF/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      const link = document.createElement("a")
      link.setAttribute(
        "href",
        "data:application/octet-stream;base64," +
          encodeURIComponent(respuesta.data.pdf_text)
      )
      link.setAttribute("download", "Afiliacion.pdf")
      link.style.display = "none"
      document.body.appendChild(link)
      link.click()
      
      if(PDFAfiliacion.length === 0 ){
        dispatch(Get_PDF_Afiliacion([{
          pdf_text: respuesta.data.pdf_text
        }]))
      }else{
  
        PDFAfiliacion.push({
          pdf_text: respuesta.data.pdf_text
        })
        dispatch(Get_PDF_Afiliacion(PDFAfiliacion))
  
      }

      setDescargando(false)
      toast.success("Descarga exitosa.")
      
    } catch (error) {
      setDescargando(false)
      toast.success("Ocurrio un error con el servidor, intente mas tarde.")
    }
  }
}